<template>
	<div class="star-rating">
        <label class="star-rating__star" v-for="rating in ratings" 
        :class="{'is-selected': ((value >= rating) && value != null), 'is-disabled': disabled}" 
        v-on:click="set(rating)" v-on:mouseover="star_over(rating)" v-on:mouseout="star_out">
        <input class="star-rating star-rating__checkbox" type="radio" :value="rating" :name="name" 
        v-model="value" :disabled="disabled"><icon icon-name="star"></icon></label>
    </div>
</template>
<script>
	export default {
  	name: "StarRating",
  	props: {
	    name: String,
	    disabled: Boolean,
	},
	data: function() {
	    return {
	    	value: null,
	      	temp_value: null,
	      	ratings: [1, 2, 3, 4, 5]
	    };
  	},
	methods: {
    	star_over: function(index) {
	      var self = this;

	      if (!this.disabled) {
	        this.temp_value = this.value;
	        return this.value = index;
	      }

	    },

	    star_out: function() {
	      var self = this;

	      if (!this.disabled) {
	        return this.value = this.temp_value;
	      }
	    },

	    set: function(value) {
	      var self = this;

	      if (!this.disabled) {
	        this.temp_value = value;
	        return this.value = value;
	      }
	    }
  },
  watch:{
  		value(value){
  			this.$emit('changeRating', value)
  		}
  }
}
</script>
<style lang="scss">
	%visually-hidden {
	  position: absolute;
	  overflow: hidden;
	  clip: rect(0 0 0 0);
	  height: 1px; width: 1px;
	  margin: -1px; padding: 0; border: 0;
	}

	.star-rating {
		display: flex;
		align-items: center;
		margin-bottom: 36px;
		&__star {
			width: 56px;
			height: 56px;
	    	display: inline-block;
	    	padding: 3px;
	    	vertical-align: middle;
	    	line-height: 1;
	    	font-size: 1.5em;
	    	color: #ABABAB;
	    	transition: color .2s ease-out;
	    	background: #F1F2F6;
			border-radius: 16px;
			margin-right: 16px;
			display: flex;
    		align-items: center;
    		justify-content: center;
    		&.is-selected{
    			svg{
    				path{
    					fill: #FCB016;
    					stroke: #FCB016;
    				}
    			}
    		}
			&:last-child{
				margin-right: 0px;
			}

	    &:hover {
	      cursor: pointer;
	    }
	    
	    &.is-selected {
	      color: #FCB016;
	    }
	    
	    &.is-disabled:hover {
	      cursor: default;
	    }
	  	}

	  	&__checkbox {
	    	@extend %visually-hidden;
	  	}
	}

</style>