<template>
  <modal-layout @close="$emit('close')">
    <div class="unsubscribe-modal-wrap p-3">
      	<div class="img">
      		<img src="/img/Alert.svg">
      	</div>
      	<p class="title">{{ $t('youReallyUnsubscribe') }}</p>
      	<p class="subtitle-head">{{ $t('yourMark') }} </p>

		<rating-star @changeRating="changeRating"/>
	
		<div class="checkbox-wrap">
			<div class="form-group">
		      <input type="radio" id="html" value="'Не устраивает цена'" v-model="comment">
		      <label for="html">Не устраивает цена </label>
		    </div>
		    <div class="form-group">
		      <input type="radio" id="css" value="'Сменил адрес страхования'"  v-model="comment">
		      <label for="css">Сменил адрес страхования</label>
		    </div>
		    <div class="form-group">
		      <input type="radio" id="css1" value="'Хочу изменить тип покрытия'"  v-model="comment">
		      <label for="css1">Хочу изменить тип покрытия</label>
		    </div>
		    <div class="form-group">
		      <input type="radio" id="css2" value="'Информативность'"  v-model="comment">
		      <label for="css2">Информативность</label>
		    </div>
		     <div class="form-group">
		      <input type="radio" id="css3" value="5"  v-model="showComment" @click="openComment">
		      <label for="css3">Оставьте свой отзыв или пожелание</label>
		    </div>
		</div>	
		
		<div class="wrap-buttons">
			<p class="err-m" v-if="validate_message">{{validate_message}}</p>
			<button class="primary-btn" @click="saveForm">
		      	{{$t('confirm')}}
		    </button>
		    <a class="close-btn" @close="$emit('close')" @click="closeModal">
		    	{{$t('close')}}
		    </a>
		</div>
	</div>
  </modal-layout>
</template>

<script>
	import RatingStar from "@/components/StarRatingComment";
	import CommentModal from "@/components/Modals/CommentModal";
	import {mapGetters, mapActions} from "vuex";

	export default {
	  	name: "UnsubscribeModal",
	  	props:{
	  		product_id: Number
	  	},
	  	components:{
	  		RatingStar
	  	},
	  	computed: {
			...mapGetters({
		      	authUser: 'authUser',
		      	commentModel: 'itemSubscription/comment',
		   	}),
		},
	  	data(){
		  	return{
		  		showComment: false,
		  		selectedArray: [],
		  		data_success:{
			        title: this.$i18n.t('canceledSubscription_1'),
			        subtitle: this.$i18n.t('canceledSubscription_2'),
			    },
			    rating: null,
		  		comment: null,
		  		validate_message: null,
		  	}
	  	},
	  	methods:{
	  		...mapActions({
		      changeCommentAction: 'itemSubscription/changeComment',
		      changeRatingAction: 'itemSubscription/changeRating',
		      changeShowVerify: 'itemSubscription/changeShowVerify',
		      changeParamOtp: 'itemSubscription/changeParamOtp',
		    }),
	  		changeRating(value){
	  			this.rating = value;
	  		},
	  		closeModal(){
	  			this.$emit('close');
	  			return null;
	  		},
	  		openComment(){
	  			if(!this.showComment){
	  				this.comment = null;
	  				this.$modal.show(CommentModal, { info: 'comment' }, {
						adaptive: true,
						height: '70%',
						width: '100%',
						shiftY: 1
					});
	  			}
	  		},
	  		saveForm(){
	  			let loader = this.$loading.show();

	  			//комментарий save
  				let comment_main = this.commentModel ? this.commentModel : this.comment;
  				this.rating = this.rating ? this.rating : 3;
  				
  				this.changeCommentAction(comment_main);
  				this.changeRatingAction(this.rating);
  				this.changeShowVerify(true);
  				this.changeParamOtp('destroy');
  				this.closeModal();

  				loader.hide();
	  		}
	  	},
	  	watch:{
	  		changeComment(value){
	  			this.comment = value;
	  		}
	  	}
	}
</script>

<style lang="scss">
	.err-m{
		color: rgb(255, 0, 93);
		margin-bottom: 16px;
		text-align: center;
	}
	.unsubscribe-modal-wrap{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.img{
			background: #FFFFFF;
			border: 2px solid #F1F2F6;
			border-radius: 24px;
			width: 96px;
			height: 96px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 16px;
		}

		.title{
			font-family: 'Manrope';
			font-weight: 700;
			font-size: 20px;
			line-height: 28px;
			text-align: center;
			color: #071222;
			margin-bottom: 16px;
		}

		.subtitle-head{
			font-family: 'Manrope';
			font-weight: 500;
			font-size: 16px;
			line-height: 20px;
			text-align: center;
			color: #071222;
			margin-bottom: 32px;
		}

		.form-group {
		  	display: block;
		  	margin-bottom: 8px;
		  	padding: 0px;
		  	height: 32px;
		  	input {
			  padding: 0;
			  height: initial;
			  width: initial;
			  margin-bottom: 0;
			  display: none;
			  cursor: pointer;
			}
			label {
				position: relative;
				cursor: pointer;
				font-family: 'Manrope';
				font-weight: 500;
				font-size: 12px;
				line-height: 16px;
				text-align: center;
				color: #071222;
				padding: 8px 12px;
				background: #F1F2F6;
				border-radius: 12px;
				margin-right: 8px;
			}
		}

		.form-group input:checked + label {
		 	color: #fff;
		 	background: #2AA65C;
		}

		.checkbox-wrap{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}

		.wrap-buttons{
			display: flex;
			flex-direction: column;
			width: 100%;
			position: fixed;
    		bottom: 16px;
    		padding: 0 16px;
			.primary-btn{
				width: 100%;
				margin-bottom: 16px;
			}
			.close-btn{
			    color: #fff;
			    color: #2AA65C;
			    border-radius: 12px;
			    font-weight: bold;
			    height: 50px;
			    display: flex;
			    justify-content: center;
			    align-items: center;
			    padding: 0 16px;
			    font-size: 16px;
				width: 100%;
				background-color: transparent;
				border: 1px solid #2AA65C;
			}
		}
	}
</style>