<template>
  <modal-layout @close="$emit('close')" @save="saveForm" :title="$t('comment')" :showButton="true" >
  		<div class="comment-wrap">
  			<!-- <p class="example-title">Например: я люблю halyk insurance</p> -->
  			<textarea rows="5" name="comment" class="comment-form" autofocus v-model="info"></textarea>
  		</div>
  </modal-layout>
</template>

<script>
	import {mapGetters, mapActions} from "vuex";
export default {
  	name: "CommentModal",
  	data() {
	    return {
	      	info: '',
	    }
	},
   	methods: {
   		...mapActions({
	      changeComment: 'itemSubscription/changeComment',
	    }),
	    saveForm(){
	    	this.changeComment(this.info);
	      	this.$emit('close');
	    }
  	}
}
</script>

<style lang="scss" scoped>
	.comment-wrap{
		padding-left: 16px;
		padding-right: 16px;
	}
	.example-title{
		font-family: 'Manrope';
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
		color: #99A3B3;
		margin-bottom: 8px;
		margin-top: 11px;
	}
	textarea.comment-form{
		width: 100%;
		height: 100px;
		border: none;
		outline: none;
		-webkit-box-shadow: none;
    	-moz-box-shadow: none;
    	box-shadow: none;
		resize: none;
		font-family: 'Manrope';
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		color: #071222;
	}
</style>
